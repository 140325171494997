module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-53BW7N4ED9","AW-339905135"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1163590967430917"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ekowkra","short_name":"Ekowkra","lang":"pl","start_url":"/","background_color":"#fff","theme_color":"#59b4d0","display":"standalone","icon":"src/assets/favicons/favicon-32x32.jpg","cache_busting_mode":"none","icons":[{"src":"src/assets/favicons/android-chrome-192x192.jpg","sizes":"192x192","type":"image/jpg","purpose":"any maskable"},{"src":"src/assets/favicons/android-chrome-512x512.jpg","sizes":"512x512","type":"image/jpg","purpose":"any maskable"},{"src":"src/assets/favicons/apple-touch-icon.jpg","sizes":"512x512","type":"image/jpg","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
